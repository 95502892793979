import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';

import {ControlErrorComponent} from './components/control-error/control-error.component';
import {ControlErrorsDirective} from './directives/control-errors.directive';

@NgModule({
  declarations: [ControlErrorsDirective, ControlErrorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [ControlErrorsDirective],
})
export class FormErrorsModule { }
